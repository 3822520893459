import React from 'react';
import styled from 'styled-components';
import { FlexList } from './FlexList';
// import { Paragraph } from './Paragraph';
// import { useContext } from 'react';
// import { LanguageContext } from '../layout/Layout';
import { Block, Col } from './Blocks';
import { Heading } from './Text/Text';

const ColWithHeading = ({ heading, col = 2, children }) => (
  <>
    <Block>
      <Heading h="3" size="med">
        {heading}
      </Heading>
    </Block>
    <Block>
      <Col type={col}>{children}</Col>
    </Block>
  </>
);

export default ColWithHeading;
