import { above, below, minWidth } from '@styles/breakpoints';
import styled from 'styled-components';
const MainBlock = styled.div`

  ${below.med`
    padding-top: 100px;
    padding-bottom: 100px;
  `}

  ${above.med`
    padding-top: 150px;
    padding-bottom: 150px;
  `}

  ${above.fullHD`
    padding-top: 250px;
    padding-bottom: 250px;
  `}

  ${minWidth(2560)`
    padding-top: 200px;
    padding-bottom: 400px;
  `}
`;

export default MainBlock;
