import React from 'react';
import { Paragraph } from './Paragraph';
import styled from 'styled-components';
import { fontSize } from '../styles/fonts';
import { Flex } from './Flex';
import { above, below } from '../styles/breakpoints';

export const Quote = styled(({ className, style, quoteText, quoteBy }) => {
  return (
    <Flex column align="center" className={className} style={style}>
      <QuoteStart />
      <Paragraph size="ingress" weight="bold" center style={{ margin: '0' }}>
        {quoteText}
      </Paragraph>
      <QuoteEnd />
      {quoteBy && (
        <Paragraph size="label" weight="medium" center style={{ marginBottom: 0 }}>
          {quoteBy}
        </Paragraph>
      )}
    </Flex>
  );
})`
  margin-right: auto;
  margin-left: 0;
  width: 100%;
`;

const QuoteStart = styled(({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.78 10.83" className={className}>
    <g>
      <path d="M10,9.24v1.59h7V7.88H13C13.34,5,15,3.05,17.78,3.05V0C13.31,0,10,3.61,10,9.24" />
      <path d="M0,9.24v1.59H7V7.88H3C3.34,5,5,3.05,7.78,3.05V0C3.31,0,0,3.61,0,9.24" />
    </g>
  </svg>
))`
  font-size: ${fontSize('ingress')};
  height: 1em;
  width: 1em;
  margin-bottom: 0.25em;
`;
const QuoteEnd = styled(({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.78 10.83" className={className}>
    <g>
      <path d="M7.78,1.59V0H.84V3h4C4.44,5.79,2.77,7.78,0,7.78v3.05c4.47,0,7.78-3.61,7.78-9.24" />
      <path d="M17.78,1.59V0H10.84V3h4C14.44,5.79,12.77,7.78,10,7.78v3.05c4.47,0,7.78-3.61,7.78-9.24" />
    </g>
  </svg>
))`
  font-size: ${fontSize('ingress')};
  height: 1em;
  width: 1em;
  margin-top: 0.25em;
`;
