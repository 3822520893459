import React from 'react'
import { graphql, Link } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import { Layout } from '../layout/Layout'
import { Flex } from '../components/Flex';
import styled from 'styled-components';
import { Weight, fontSize } from '../styles/fonts';
import { Hover } from './Hover';

const _FlexList = styled(Flex)`
  padding: 0;
  flex-grow: 1;
  width: 100%;
  min-height: 70%;
  li {
    list-style: none;
    a {
      font-size: ${fontSize('title')};
      font-weight: ${Weight.bold};
      line-height: 1.5;
    }
  }
`

export const FlexList = ({ data, className }) => (
  <_FlexList as="ul" column justify="center" align="center" grow className={className}>
    {data.map(({ id, title, url, }) => (
      <li key={id}>
        <Link to={url}>
          <Hover>
            {title}
          </Hover>
        </Link>
      </li>
    ))}
  </_FlexList>
)

