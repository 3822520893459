import React from 'react';
import { animated } from 'react-spring';
import { useScrollReveal } from '@hooks/useScrollReveal';
import { Block } from './Blocks';
const Animated = animated(Block);

const AnimatedBlock = ({ children, ...props }) => {
  const [ref, animProps] = useScrollReveal();

  return (
    <Animated blockRef={ref} style={animProps} {...props}>
      {children}
    </Animated>
  );
};

export default AnimatedBlock;
