import { imageUrlFor } from '@lib/image-url';
import { useMemo } from 'react';

const useSanityImage = (url, { width, height }) =>
  useMemo(() => {
    if (!url) return;
    const image = imageUrlFor(url).width(width);
    return height ? image.height(height) : image;
  }, [url, width, height]);

export default useSanityImage;
