import React from 'react';
import { above } from '@styles/breakpoints';
import styled from 'styled-components';
import AnimatedBlock from './AnimatedBlock';
import { Width } from './Blocks';

const Align = styled.div`
  ${above.med`
    margin-left: ${(props) => (props.align === 'right' ? '15%' : 0)}
    margin-right: ${(props) => (props.align === 'left' ? '15%' : 0)}
  `}
`;

const BlockWidth = ({ align = 'left', width = { type: 'l' }, children, ...block }) => (
  <AnimatedBlock {...block}>
    {/* <Width {...width}> */}
    <Align align={align}>{children}</Align>
    {/* </Width> */}
  </AnimatedBlock>
);

export default BlockWidth;
