import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import localeString from '@lib/locale-string';

import { LanguageContext } from '../layout/Layout';

import '@queries/index-page';

import SEO from '@components/seo';
import { Quote } from '@components/Quote';
import { Block } from '@components/Blocks';
import { Ingress, Paragraph } from '@components/Text/Text';
import BlockWidth from '@components/BlockWidth';
import MainBlock from '@components/MainBlock';
import ColWithHeading from '@components/ColWithHeading';
import Card, { CardLarge } from '@components/Card/Card';
import FlexedList from '@components/FlexedList/FlexedList';
import WithErrors from '@components/WithErrors';

export const query = graphql`
  query IndexPageQuery {
    ...IndexPage
  }
`;

const defaultCategory = {
  en: 'General',
  nb: 'Generelt',
};

const IndexPage = ({ data }) => {
  const lang = useContext(LanguageContext);

  const site = (data || {}).site;
  const index = (data || {}).index;

  const ingress = localeString(lang, index?.ingress);
  const { topical, services } = index;

  const featuredCases =
    index.featuredCases &&
    index.featuredCases.reference.map((c) => ({
      id: c.id,
      title: localeString(lang, c.title),
      image: c.image,
      category: localeString(lang, c?.category?.category, 'nb', defaultCategory[lang]),
      url: `/${lang}/case/${c.slug.current}`,
    }));
  const quoteText = index.quoteText.nb;
  const quoteBy = index.quoteBy.nb;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <div className="main">
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <BlockWidth align="right">
        <MainBlock>
          <Block>
            <Ingress>{ingress}</Ingress>
          </Block>
          {topical && !!topical.articles.length && (
            <Block type="s">
              <FlexedList items={topical.articles}>
                <Paragraph>{localeString(lang, topical.label)}:</Paragraph>
              </FlexedList>
            </Block>
          )}
        </MainBlock>
      </BlockWidth>
      {services && !!services.articles.length && (
        <BlockWidth align="right">
          <ColWithHeading heading={localeString(lang, services.heading)} col="3-x">
            {services.articles.map(({ title, description, slug: { current }, ...props }) => (
              <Card
                key={current}
                url={`/${lang}/page/${current}`}
                title={localeString(lang, title)}
                description={localeString(lang, description)}
                {...props}
              />
            ))}
          </ColWithHeading>
        </BlockWidth>
      )}
      <BlockWidth type="h">
        <Quote quoteText={quoteText} quoteBy={quoteBy} />
      </BlockWidth>
      {featuredCases && featuredCases.length && (
        <BlockWidth type="h" align="right">
          <ColWithHeading
            heading={localeString(lang, index.featuredCases.heading, 'nb', 'Utvalgte arbeider')}
            align={'right'}
            col="2"
          >
            {featuredCases.map((props) => (
              <CardLarge key={props.id} {...props} />
            ))}
          </ColWithHeading>
        </BlockWidth>
      )}
    </div>
  );
};

export default WithErrors(IndexPage);
