import { Link } from '@components/Text/Text';
import React from 'react';
import { below, above } from '@styles/breakpoints';
import styled from 'styled-components';
import { fontSize } from '@styles/fonts';

const StyledFlexedList = styled.div`
  display: flex;
  flex-flow: row;

  > * {
    margin: 0;
  }

  ${below.hdReady`
    font-size: ${({ size = 'med' }) => fontSize(size)};
  `}

  ${above.hdReady`
    font-size: ${({ size = 'small' }) => fontSize(size)};
  `}

  ${below.large`
    flex-flow: column;
    > * {
      display: block;
      width: 100%;
      padding: 0.5em 0;
    }
  `}

  ${above.large`
    > * {
      &:not(:first-child) {
        padding: 0.5em;
      }

      &:first-child {
        padding: 0.5em 0.5em 0.5em 0;
      }
    }
  `}
`;

const FlexedList = ({ items = [], children }) => {
  return (
    <StyledFlexedList>
      {children}
      {items.map(({ localeTitle, slug: { current }, title = localeTitle.nb }) => (
        <React.Fragment key={current}>
          {' '}
          <Link className="text-link" to={`/newsletter/${current}`}>
            {title}
          </Link>
        </React.Fragment>
      ))}
    </StyledFlexedList>
  );
};

export default FlexedList;
