import React from 'react';
import { Heading, Paragraph } from '@components/Text/Text';
import { Link } from 'gatsby';

import styled from 'styled-components';
import { Block } from '@components/Blocks';
import { Hover } from '@components/Hover';
import useSanityImage from '@hooks/useSanityImage';

// const CardFigure = ({ alt = 'Hei', children, ...props }) => {
//   return styled.div`
//     background-color: ${({ theme }) => {
//       log
//     }}
//   `;
// };

const CardInner = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.secondary};
  display: flex;
  flex-flow: column;

  > div:first-child {
    flex: 1;
  }

  > div:last-child {
    margin-top: auto;
  }
`;

const CardBody = styled.div`
  padding: 25px 20px;
`;

const CardFigure = styled.figure`
  position: relative;
  width: 100%;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  height: 100%;
  overflow: hidden;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-color: ${({ theme }) => theme.primary};
  background-size: cover;
`;

const CardImage = ({ url, imageSize, ...props }) => {
  const sanityURL = useSanityImage(url, imageSize);
  return <CardFigure backgroundImage={sanityURL} {...props} />;
};

const CardOuter = styled(Link)`
  height: 100%;
  display: block;
`;

const Card = ({ image, imageSize, paddingBottom = '54.5454545455%', url, children }) => (
  <Hover maxDegree={5} height="100%" display="block">
    <CardOuter to={url}>
      <CardInner>
        <Block type="m">
          <CardImage
            imageSize={imageSize}
            paddingBottom={paddingBottom}
            url={image?.asset?.url}
            className="figure-default"
          />
        </Block>
        <Block type="0">
          <CardBody>{children}</CardBody>
        </Block>
      </CardInner>
    </CardOuter>
  </Hover>
);

export const CardLarge = ({ category = 'Generelt', title, ...props }) => (
  <Card imageSize={{ width: '500' }} {...props} paddingBottom={'68.8311688312%'}>
    <Block type="xt">
      <Paragraph>{category}</Paragraph>
    </Block>
    <Block type="xt">
      <Heading size="label">{title}</Heading>
    </Block>
  </Card>
);

export default ({ title, description, category, ...props }) => (
  <Card imageSize={{ width: '320' }} {...props}>
    {category && (
      <Block type="xt">
        <Paragraph>{category}</Paragraph>
      </Block>
    )}
    {title && (
      <Block type="xt">
        <Heading size="med">{title}</Heading>
      </Block>
    )}
    {description && (
      <Block type="xt">
        <Paragraph size="small">{description}</Paragraph>
      </Block>
    )}
  </Card>
);
