import React from 'react';
import { Layout } from '@components/Blocks';
import GraphQLErrorList from '@components/graphql-error-list';

const WithErrors = (Component) => ({ errors, ...props }) =>
  errors ? (
    <Layout>
      <GraphQLErrorList errors={errors} />
    </Layout>
  ) : (
    <Component {...props} />
  );

export default WithErrors;
