import React from 'react';
import styled from 'styled-components';
import { fontSize, Weight } from '../styles/fonts';
import { animated } from 'react-spring';
import { above } from '../styles/breakpoints';

const P = React.forwardRef((props, ref) => {
  const { children, className, style, onClick } = props;

  return (
    <p className={className} style={style} ref={ref} onClick={onClick}>
      {children}
    </p>
  );
});

export const Paragraph = styled(P)`
  font-size: ${(props) => fontSize(props.size)};
  font-weight: ${(props) => Weight[props.weight || 'medium']};
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export const Heading = styled(P)`
  font-size: ${(props) => fontSize(props.size)};
`;

export const TextBox = styled(Paragraph)`
  width: 70%;
  margin-right: 5%;
  margin-left: auto;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: ${({ margin }) => (margin ? `1em` : 0)};
  margin-bottom: ${({ margin }) => (margin ? `1em` : 0)};
  ${above.fullHD`
        width: 65%;
    `}
`;
export const TextBoxLeft = styled(TextBox)`
  margin-left: 5%;
`;
export const AParagraph = animated(Paragraph);
export const AHeading = animated(Heading);
